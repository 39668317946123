







































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {shipArrivalStoreActions, shipArrivalStoreGetter, shipArrivalStoreMutations} from '@/store/shipArrival.store';
import ShipArrival from '@/models/ShipArrival.model';
import {BookingState} from '@/enums/BookingState.enum';
import {DateTime} from 'luxon';

const ShipArrivalStore = namespace('shipArrival');

@Component({
  components: {
    ShipArrivalTableComponent: () => import(
        /* webpackChunkName: "ShipArrivalTableComponent" */
        '@/components/shipArrival/ShipArrivalTable.component.vue'),
    CreateShipArrivalComponent: () => import(
        /* webpackChunkName: "CreateShipArrivalComponent" */
        '@/components/shipArrival/create/CreateShipArrival.component.vue'),
  }
})
export default class ShipArrivalView extends Vue {

  @ShipArrivalStore.Action(shipArrivalStoreActions.GET_ALL)
  private getAllArrivals!: (payload?: {
    filterText?: string, status?: BookingState,
    range?: string[], withArchive?: boolean
  }) => Promise<ShipArrival[]>;

  @ShipArrivalStore.Action(shipArrivalStoreActions.DELETE)
  private deleteShipArrivalAction!: (payload: { id: string }) => void;

  @ShipArrivalStore.Getter(shipArrivalStoreGetter.LIST_ALL)
  private allArrivals!: ShipArrival[];

  @ShipArrivalStore.Mutation(shipArrivalStoreMutations.SET_CURRENT_ARRIVAL)
  private setCurrentArrival!: (shipArrival: ShipArrival | null) => void;

  public searchValue: string = '';
  public selectedFilter: BookingState = BookingState.ALL;
  public showCreationDialog: boolean = false;

  public dateMenu: boolean = false;
  public dateFilter: string[] = [];
  public isLoading: boolean = false;
  public loadArchive: boolean = false;

  get formattedDate(): string {
    if (this.dateFilter.length === 2) {
      const start = DateTime.fromFormat(this.dateFilter[0], 'yyyy-MM-dd');
      const end = DateTime.fromFormat(this.dateFilter[1], 'yyyy-MM-dd');
      return start < end ? `${start.toFormat('dd.MM.yyyy')} - ${end.toFormat('dd.MM.yyyy')}` :
          `${end.toFormat('dd.MM.yyyy')} - ${start.toFormat('dd.MM.yyyy')}`;
    } else return ''
  }

  public filterItems: { text: string, value: string }[] = [{
    text: 'GENERAL.FILTER.ALL',
    value: BookingState.ALL
  }, {
    text: 'GENERAL.FILTER.ACCEPTED',
    value: BookingState.ACCEPTED
  }, {
    text: 'GENERAL.FILTER.RESERVED',
    value: BookingState.RESERVED
  }, {
    text: 'GENERAL.FILTER.NO_BOOKING',
    value: BookingState.NO_BOOKING
  }, {
    text: 'GENERAL.FILTER.DELETED',
    value: BookingState.DELETED
  }];

  public async created() {
    // const startDate = DateTime.now().startOf('week').toFormat('yyyy-MM-dd');
    // const endDate = DateTime.now().endOf('week').toFormat('yyyy-MM-dd');
    // this.dateFilter = [startDate, endDate];
    await this.statusFilterChanged();
  }

  public get getLocale() {
    if (this.$i18n.locale === 'en') {
      return 'en-US';
    } else {
      return 'de-DE';
    }
  }

  public dateFilterChanged() {
    this.statusFilterChanged();
    // @ts-ignore
    (this.$refs.menu as any).save(this.dateFilter)
  }

  public clearDateFilter() {
    this.dateFilter = [];
    this.statusFilterChanged();
  }

  public async statusFilterChanged() {
    try {
      this.isLoading = true;
      const params: { filterText?: string, status?: BookingState, range?: string[], withArchive?: boolean } = {};
      if (this.searchValue) {
        params.filterText = this.searchValue
      }
      if (this.selectedFilter !== BookingState.ALL) {
        params.status = this.selectedFilter;
      }
      if (this.dateFilter.length > 0) {
        params.range = this.dateFilter;
      }

      if (this.loadArchive) {
        params.withArchive = true;
      }

      await this.getAllArrivals(params);
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    } finally {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  }

  public async onDeleteShipArrival(shipArrival: ShipArrival) {
    try {
      await this.deleteShipArrivalAction({id: shipArrival.id!});
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.GENERAL_SUCCESS');
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  public onEditShipArrival(shipArrival: ShipArrival) {
    this.setCurrentArrival(shipArrival);
    this.showCreationDialog = true;
  }

  public showCreationDialogClicked() {
    this.setCurrentArrival(new ShipArrival());
    this.showCreationDialog = true;
  }

  public async closeCreationDialog(reload: boolean) {
    this.showCreationDialog = false;
    this.setCurrentArrival(null);
    if (reload) {
      await this.statusFilterChanged();
    }
  }
}
